// @ts-nocheck
import vueCookie from 'vue-cookies'
import http, { post, fetch } from './https.ts'
import store from '../store'

/*
* toFixed 精度问题，重写JS 原生方法
**/
Number.prototype.toFixed = function (s) {
    let changenum = (parseInt(this * Math.pow(10, s) + (this > 0 ? 0.51 : -0.51)) / Math.pow(10, s)).toString()
    let index = changenum.indexOf('.')
    if (index < 0 && s > 0) {
      changenum = changenum + '.'
      for (let i = 0; i < s; i++) {
        changenum = changenum + '0'
      }
    } else {
      index = changenum.length - index
      for (let i = 0; i < (s - index) + 1; i++) {
        changenum = changenum + '0'
      }
    }
    return changenum
}

Date.prototype.format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "h+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    }
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length))
        for (var k in o){
            if (new RegExp("(" + k + ")").test(fmt)) {
              fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
            }
        }
    return fmt
}

const tools = {
    /**
    * 设置localStorage
    * @key  @value
    * */
    setLocalStorage (key, value) {
        window.localStorage.setItem(key, JSON.stringify(value))
    },
    /****
    * 获取localStorage的值
    * @key
    */
    getLocalStorage (key) {
        return JSON.parse(window.localStorage.getItem(key))
    },
    /**
    * 删除localStorage
    * @param key
    */
    removeLocalStorage (key) {
        window.localStorage.removeItem(key)
    },
    /**
    * 删除 all localStorage
    * @param
    */
    clearAllLocalStorage () {
        window.localStorage.clear()
    },
    /**
    * set sionStorage
    * @key  @value
    * */
    setSessionStorage (key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value))
    },
    /****
    * 获取sessionStorage的值
    * @key
    */
    getSessionStorage (key) {
        return JSON.parse(window.sessionStorage.getItem(key))
    },
    /**
    * 删除sessionStorage
    * @param key
    */
    removeSessionStorage (key) {
        window.sessionStorage.removeItem(key)
    },
    /**
    * 删除 all localStorage
    * @param
    */
    clearAllSessionStorage () {
        window.sessionStorage.clear()
    },
    // 计算时间差h:m:s
    differenceTime(startTime, endTime = new Date().getTime()) {
      const dMs = endTime - startTime
      // 相差天数
      const dDay = Math.floor(dMs / (24 * 3600 * 1000))
      // 小时
      const lHour = dMs % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
      const dHour = Math.floor(lHour / (3600 * 1000)) // 相差小时数
      // 分钟
      const lMin = lHour % (3600 * 1000) // 计算小时数后剩余的毫秒数
      const dMin = Math.floor(lMin / (60 * 1000)) // 相差分钟数
      // 秒
      const lSec = lMin % (60 * 1000) // 计算分钟数后剩余的毫秒数
      const dSec = Math.round(lSec / 1000) // 相差秒数
      // 天数转小时
      const hours = dDay * 24 + dHour
      return `${hours}h:${dMin}m:${dSec}s`
    },
    getUrlParam (url) {
      let param = {}
      if (url.includes('?')) {
        let str = url.split('?')[1]
        let arr = str.includes('&') ? str.split('&') : [str]
        arr.forEach(e => {
          if (e.includes('=')) {
            param[e.split('=')[0]] = decodeURI(e.split('=')[1])
          }
        })
      }
      return param
    },
    getUserInfo() {
      let userInfo = store.state.userInfo
      if (userInfo && Object.keys(userInfo) > 0) {
        return userInfo
      } else {
        return JSON.parse(sessionStorage.getItem('userInfo') || '{}')
      }
    },
    clearLoginInfo () {
      let baseUrl = process.env.VUE_APP_DOMAIN
      vueCookie.remove('token', { domain: baseUrl })
      sessionStorage.clear()
      localStorage.removeItem('caseSet', '')
      localStorage.removeItem('loginid', '')
      localStorage.removeItem('userId', '')
      localStorage.removeItem('tenantid', '')
    }
}

const filters = {
    /**
    * 格式化千位符,钱的格式,
    * @num  10000
    * return  1,000
    * **/
    formatMoney (num, isDecimal) {
      num = filters.formatNumber(num)
      if (isNaN(num)) {
        return ''
      }
      if (!isDecimal) {
        num = Number(num).toFixed(2)
      }
      if (!/^(\+|-)?(\d+)(,\d{3})*(\.|\.\d+)?$/.test(num)) {
        return num
      }
      let re = /(\d)(\d{3})(,|$)/, a = RegExp.$1, b = RegExp.$2, c = RegExp.$4
      while (re.test(b)) {
        b = b.replace(re, '$1,$2$3')
      }
      let value = a + '' + b + '' + c
      return value
    },
    /**
    * 还原number 类型,
    * @strval  1,000
    * return 1000
    * **/
    formatNumber (str) {
      str += ''
      if (str.indexOf(',') === -1) {
        return str
      }
      return str.replace(/,/g, '')
    },
    /***
    * 格式化数字类型,保留2位小数
    * @x  100
    * return 100.00
    * **/
    toDecimal2 (x) {
      if (!x) {
        return ''
      }
      let s = x.toString()
      let rs = s.split('.')
      let left = rs[0]
      let right = rs[1]
      if (right) {
        if (right.length > 2) {
          right = right.substring(0, 2)
        } else if (right.length < 2) {
          right += 0
        }
      } else {
        right = '00'
      }
      return left + '.' + right
    },
    formatDate (date, mat) {
      return tools.formatDate(date, mat)
    }
}

// 城市数据
let cityData = () => {
  return post('/biz/area/list', { isEnabled: 1 }).then(({ data }) => {
    if (data && data.code === 'C0000') {
      tools.setSessionStorage('cityData', data.result)
      return data.result
    }
  })
}

// 币别数据
let currencyData = () => {
  return post('/cc/currency/list', { isEnabled: 1, pageNum: 1, pageSize: 9999 }).then(({ data }) => {
    if (data && data.code === 'C0000') {
      tools.setSessionStorage('currencyData', data.result.list)
      return data.result.list
    }
  })
}

// cc可选国家数据
let ccCountryData = () => {
  return post('/biz/area/list', { filter: 'cc', isEnabled: 1 }).then(({ data }) => {
    if (data && data.code === 'C0000') {
      tools.setSessionStorage('ccCountryData', data.result)
      return data.result
    }
  })
}

const initSessionStorageData = {
  init () {
    return Promise.all([cityData()])
  },
  getCityData () {
    return cityData()
  },
  getCurrencyData () {
    return currencyData()
  },
  getCcCountryData () {
    return ccCountryData()
  }
}
const scrollTop = () => {
  setTimeout(() => {
      document.documentElement.scrollTop = 0;
  }, 100)
}
/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth (key) {
    return JSON.parse(sessionStorage.getItem('permissions') || '[]').includes(key)
}

export default {
    tools,
    filters,
    isAuth,
    initSessionStorageData,
    scrollTop
  }