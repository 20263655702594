<template>
  <router-view/>
</template>


<script>
 export default {
  created() {
    var isMobi = navigator.userAgent.toLowerCase().match(
        /(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince|microMessenger)/i
        ) != null
    if (isMobi) {
      window.location.href = 'https://h5.dolphintec.cn/'
    }
  }
 }
</script>

<style lang="scss">
#app {
  font-family: 'Times New Roman', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  // text-align: center;
  color: #333;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
