<template>
    <el-dialog
      top="15vh"
      width="760px"
      append-to-body
      @closed="close"
      title="信息填写"
      v-model="visible"
      :close-on-click-modal="false"
      custom-class="application-dialog"
      @open="open"
    >
      <el-form    
        ref="form-data"
        :model="formData"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        label-position="top">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="手机号" prop="cellPhone" class="phone-form-item">
              <div class="cellPhoneCode-code clearfix">
                <el-select
                  v-model="formData.cellPhoneCode"
                  placeholder="请选择区号"
                >
                  <el-option label="+86" value="+86" />
                </el-select>
                <span class="line"></span>
                <el-input class="cellPhone" v-model="formData.cellPhone" placeholder="请输入手机号码" clearable />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="验证码" prop="verificationCode" class="phone-code clearfix">
              <div class="ph-code-number">
                <el-input
                  v-model="formData.verificationCode"
                  autocomplete="off"
                  clearable
                  placeholder="请输入手机验证码"
                ></el-input>
              </div>
              <div class="ph-code-btn" style="font-size: 0;">
                <el-button
                  size="small"
                  :disabled="isCodeDisabled || !isCode"
                  autocomplete="off"
                  @click="checkedPhoneCode"
                  >{{ phoneCodeBtn }}</el-button
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请人" prop="linkMan">
              <el-input
                v-model="formData.linkMan"
                clearable
                placeholder="请输入申请人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司" prop="companyName">
              <el-input
                v-model="formData.companyName"
                clearable
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业规模" prop="companySize">
              <el-select
                v-model="formData.companySize"
                placeholder="请选择企业规模"
              >
                <el-option label="小微企业（ 年营收 ≤ 5000万元 ）" value="1" />
                <el-option label="中型企业（ 5000万元 < 年营收 ≤ 20亿元 ）" value="2" />
                <el-option label="大型企业（ 年营收 > 20亿元 ）" value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职务" prop="post">
              <el-input
                v-model="formData.post"
                clearable
                placeholder="请输入职务"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="需求" prop="demand">
          <el-input
            type="textarea"
            style="border: none; box-shadow: 0 0 0 0px; resize: none"
            v-model="formData.demand"
            clearable
            :rows="3"
            placeholder="请输入需求"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
            <el-button @click="close" style="border-left: 1px solid #DBDFE6;" :loading="btnLoading">取 消</el-button>
            <el-button type="primary" @click="confirm('form-data')" :loading="btnLoading">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </template>
  <script>
  export default {
    name: 'application', // 地址组件
    props: {
      show: {
        type: Boolean,
        default: false
      },
      phone: {
        type: String,
        default: ''
      },
      tableHeight: {
        type: Number,
        default: 300
      }
    },
    data () {
      var phoneVali = (rule, value, callback) => {
       if (value && !/^1[3-9]\d{9}$/.test(value)) {
          callback('请输入正确的手机号')
        }else {
          callback()
        }
      }    
      return {
        visible: false,
        formData: {cellPhoneCode: '+86'},
        rules: {
          cellPhone: [
            {
              required: true,
              message: '请输入手机号码',
              trigger: 'change',
            },
            {validator: phoneVali, trigger: 'blur'}
          ],
          verificationCode: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'change',
            },
          ],
          linkMan: [
            {
              type: 'string',
              required: true,
              message: '请输入申请人',
              trigger: 'blur',
            },
          ],
          companyName: [
            {
              type: 'string',
              required: true,
              message: '请输入公司名称',
              trigger: 'blur',
            },
          ],
          companySize: [
            {
              type: 'string',
              required: true,
              message: '请选择企业规模',
              trigger: 'change',
            },
          ],
        },
        phoneCodeBtn: "获取验证码",
        isCodeDisabled: false,
        btnLoading: false
      }
    },
    computed: {
      isCode: {
        get() {
          return /^1[3-9]\d{9}$/.test(this.formData.cellPhone)
        }
      }
    },
    methods: {
      confirm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            this.$post("/crm/crmDolphinDemand/add", this.formData).then(({data}) => {
              if(data && data.code === 'C0000') {
                this.$message.success(
                    {
                        message: '感谢您的申请，我们会尽快与您取得联系，请耐心等待！',
                        duration: 4000,
                        offset: 150,
                        grouping: true
                    }
                )
                this.close()
              }
            }).finally(() => {
              this.btnLoading = false
            })
          }
        });
      },
      open() {
        if(this.phone) {
          this.formData.cellPhone = this.phone
        }
      },
      checkedPhoneCode() {
        this.isCodeDisabled = true
        let that = this
        this.$post("/crm/crmDolphinDemand/sendCode", {
          cellPhone: this.formData.cellPhone,
          cellPhoneCode: this.formData.cellPhoneCode
        })
          .then(({ data }) => {
            if (data && data.code === "C0000") {
              let index = 60
              let codeTime = setInterval(() => {
                if (index <= 0) {
                  clearInterval(codeTime)
                  that.isCodeDisabled = false
                  that.phoneCodeBtn = "获取验证码"
                } else {
                  this.phoneCodeBtn = "重新获取(" + index + ")"
                  index--
                }
              }, 1000);
            } else {
              this.isCodeDisabled = false
              this.$message.error(data.message)
            }
          })
          .catch(() => {})
      },
      close () { // 关闭
        this.formData = {cellPhoneCode: '+86'}
        this.$refs['form-data'].clearValidate()
        this.$emit('close')
      }
    }
  }
  </script>
  <style lang="scss">
    .application-dialog{
      border-radius: 16px;
      .el-dialog__header{
        font-weight: bold;
        padding: 20px 30px;
      }
      .el-dialog__body{
        padding: 10px 60px 5px;
        .el-form-item{
          .el-form-item__content{
            .el-select{
              width: 100%;
            }
            .el-input__inner{
              background-color: #F2F7FF;
              height: 45px;
              line-height: 45px;
              box-shadow: none;
            }
            .el-input__wrapper{
              background-color: #F2F7FF;
              border: none;
              outline: 0;
              box-shadow: none;
              border-radius: 8px;
            }
            .is-focus{
              .el-input__wrapper{
                box-shadow: none;
              }
            }
          }
          margin-bottom: 25px;
          .el-form-item__label{
            margin-bottom: 15px;
            color: #000;
          }
          &.is-required{
            .el-form-item__label{
              &::before{
                display: none;
              }
              &::after{
                content: '*';
                color: red;
              }
            }
          }
        }
      }
      .el-dialog__footer{
        height: 75px;
        padding: 0;
      }
      .phone-code {
        div {
          float: left;
        }
        .el-form-item__content{
          width: 100%;
        }
        .ph-code-number {
          width: calc(100% - 110px);
        }
        .ph-code-btn {
          width: 110px;
          button {
            color:  #000;
            background-color: unset;
            height: 45px;
            font-size: 14px;
            border: 1px solid #0097F2;
            color: #0097F2;
            box-sizing: border-box;
            width: 110px;
            border-radius: 8px;
          }
        }
      }
    }
  </style>
  