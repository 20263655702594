// @ts-nocheck
import {createI18n} from 'vue-i18n'
import zhCN from './zh-CN'
import enUS from './en-US'

let language = JSON.parse(window.localStorage.getItem('language'))
if (language) {
    language = language.replace('-', '_')
} else {
    window.localStorage.setItem('language', JSON.stringify(process.env.VUE_APP_I18N_LOCALE.replace('-', '_')))
}
const i18n = createI18n({
locale: language || 'zh_CN', // 浏览器默认语言
messages: {
'zh_CN': zhCN,
'en_US': enUS
}
})

export default i18n