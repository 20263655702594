import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import routerConfig from './router-config'

import NotFound from '../components/404.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   // name: 'home',
  //   // component: HomeView
  //   // redirect: '/overview/home'
  //   redirect: '/login'
  // },
  ...routerConfig,
  {
    path: '/:catchAll(.*)',
   component: NotFound
 }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from) {
    return { top: 0, left: 0 }
  }
})

export default router
