import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-plus'
import './assets/css/index.scss'
import 'element-plus/dist/index.css'
import zhTw from 'element-plus/es/locale/lang/zh-tw'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import utils from './utils'
import calculation from './utils/calculation'
import './icons'
import _ from 'lodash'
import VueI18n from './language'
import https, {fetch, post, adornUrl} from './utils/https'
import componentsLib from './components/index'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import dayjs from 'dayjs'


const app = createApp(App)
app.config.globalProperties.$utils = utils.tools
app.config.globalProperties.$calc = calculation
app.config.globalProperties.$_ = _
app.config.globalProperties.$isAuth = utils.isAuth
app.config.globalProperties.$http = https
app.config.globalProperties.$get = fetch
app.config.globalProperties.$post = post
app.config.globalProperties.$adornUrl = adornUrl
app.config.globalProperties.$initSessionStorageData = utils.initSessionStorageData
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$scrollTop = utils.scrollTop

let filters = {}
Object.keys(utils.filters).forEach(key => {
    // @ts-ignore
    filters[key] = utils.filters[key]
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  // @ts-ignore
  app.component(key, component)
}

 // @ts-ignore
 componentsLib.components.map(component => {
    // @ts-ignore
   app.component(component.name, component)
 })
 
 app.config.globalProperties.$filters = filters

 // @ts-ignore
 const language = JSON.parse(window.localStorage.getItem('language'))
 let lang:any = en
 switch (language) {
  case 'en_US' :
    lang = en
    break
  case 'zh_CN':
    lang = zhCn
    break
  case 'zh_TW':
    lang = zhTw
    break
  default:
    lang = en
 }
 

app.use(store)
app.use(router)
app.use(VueI18n)
app.use(ElementUI, {
  locale: lang,
})
.mount('#app')

